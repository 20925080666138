import {AuthenticatedUser} from '@oegbv/ui-shared/dist/auth/authenticators/Authenticator'

export interface DigitalUser {
  username?: string
  email?: string
  arfbr?: 'FREE' | 'PLUS' | 'PREMIUM'
  kv?: boolean
  books?: boolean
  admin?: boolean
  lohndatenApi?: boolean
  arbvg?: boolean
  azg?: boolean
  angg?: boolean
  aschg?: boolean
  loeschnigg?: boolean
}

export enum Permission {
  API_LOHNDATEN = 'api-lohndaten',
  ARFBR_FREE = 'arfbr-free',
  ARFBR_PLUS = 'arfbr-plus',
  ARFBR_PREMIUM = 'arfbr-premium',
  BOOKS = 'books',
  CP_ADMIN_ALL = 'cp-admin-all',
  CP_ADMIN_LOHNDATEN = 'cp-admin-lohndaten',
  CP_ADMIN_INDEXING = 'cp-admin-indexing',
  KVSYSTEM = 'kvsystem',
  SETTINGS = 'settings',
  BOOK_ANGG = 'book-angg',
  BOOK_ARBVG = 'book-arbvg',
  BOOK_AZG = 'book-azg',
  BOOK_ASCHG = 'book-aschg',
  BOOK_LOESCHNIGG = 'book-loeschnigg',
  BOOK_TESTWIESE = 'book-testwiese',
}

const USER_COOKIE_NAME = 'USER'
const SESSION_COOKIE_NAME = 'SESSION'

const LOGIN_PATH = '/api/v1/auth/login'
const LOGOUT_PATH = '/api/v1/auth/logout'
const CODE_PATH = '/api/v1/auth/code'

const SESSION_RECHECK_TIME_IN_MIN = 5

const getCookieValue = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() ?? null
  } else {
    return null
  }
}

const removeAuthCookies = () => {
  document.cookie = `${SESSION_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  document.cookie = `${USER_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

function getBrowserBaseUrl() {
  if (typeof window !== 'undefined') {
    const {protocol, hostname, port} = window.location
    const portPart =
      port && ((protocol === 'http:' && port !== '80') || (protocol === 'https:' && port !== '443')) ? `:${port}` : ''
    return `${protocol}//${hostname}${portPart}`
  }
  return ''
}

export class SessionPlusAuthenticator {
  private user: DigitalUser | null = null
  private lastSessionCheck: number | null = null

  init(done: () => void) {
    const userCookieValue = getCookieValue(USER_COOKIE_NAME)
    // Reset auth state if the userCookie, that comes with the /code resources, is not set
    // this means that the auth process wasn't successful before. By removing any state, we prevent
    // sending potential invalid auth information to the server.
    if (userCookieValue === null) {
      removeAuthCookies()
    }
    this.fetchUser().then((user) => {
      if (user !== null) {
        this.startRegularSessionCheck()
      } else {
        // Reset auth state because
        removeAuthCookies()
      }
      this.user = user
      done()
    })
  }

  checkSession() {
    this.fetchUser().then((user) => {
      if (user === null) {
        this.logout()
      }
    })
  }

  startRegularSessionCheck() {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        this.checkSession()
      }
    }

    // Also trigger the check when the window regains focus.
    window.addEventListener('focus', () => this.checkSession())
    document.addEventListener('visibilitychange', handleVisibilityChange)

    setInterval(() => {
      this.checkSession()
    }, 1000 * 60 * SESSION_RECHECK_TIME_IN_MIN)
  }

  isLastSessionCheckValid() {
    if (this.lastSessionCheck === null) {
      return false
    }

    const now = new Date().getTime()
    const diff = now - this.lastSessionCheck
    return diff < (SESSION_RECHECK_TIME_IN_MIN - 1) * 60 * 1000
  }

  async fetchUser(): Promise<DigitalUser | null> {
    if (this.user !== null && this.isLastSessionCheckValid()) {
      return this.user
    }

    try {
      const response = await fetch('/api/v1/user')

      // Return null if access is forbidden or any error status is returned
      if (!response.ok || response.status >= 300) {
        console.error('authenticateSession: Error fetching user', response)
        return null
      }

      this.lastSessionCheck = new Date().getTime()
      return await response.json()
    } catch {
      return null
    }
  }

  hasPermission(permission: Permission) {
    if (this.user === null) {
      return false
    }

    if (permission === Permission.API_LOHNDATEN) {
      return this.user.lohndatenApi
    }
    if (permission === Permission.ARFBR_FREE) {
      return true
    }
    if (permission === Permission.ARFBR_PLUS) {
      return this.user.arfbr === 'PLUS' || this.user.arfbr === 'PREMIUM'
    }
    if (permission === Permission.ARFBR_PREMIUM) {
      return this.user.arfbr === 'PREMIUM'
    }
    if (permission === Permission.BOOKS) {
      return this.user.books
    }
    if (permission === Permission.CP_ADMIN_ALL) {
      return this.user.admin
    }
    if (permission === Permission.CP_ADMIN_INDEXING) {
      return this.user.admin
    }
    if (permission === Permission.CP_ADMIN_LOHNDATEN) {
      return this.user.admin
    }
    if (permission === Permission.KVSYSTEM) {
      return this.user.kv
    }
    if (permission === Permission.SETTINGS) {
      return this.user.admin
    }
    if (permission === Permission.BOOK_ARBVG) {
      return this.user.arbvg
    }
    if (permission === Permission.BOOK_LOESCHNIGG) {
      return this.user.loeschnigg
    }
    if (permission === Permission.BOOK_ANGG) {
      return this.user.angg
    }
    if (permission === Permission.BOOK_ASCHG) {
      return this.user.aschg
    }
    if (permission === Permission.BOOK_AZG) {
      return this.user.azg
    }
    if (permission === Permission.BOOK_TESTWIESE) {
      return this.user.admin
    }

    return false
  }

  isAuthenticated(): boolean {
    return this.user !== null
  }

  login(broker?: string | null, redirectUrl?: string | null): void {
    const codeCallbackUrl = `codeCallbackUrl=${encodeURIComponent(getBrowserBaseUrl() + CODE_PATH)}`
    const defaultRedirectUrl = `&redirectUrl=${encodeURIComponent(getBrowserBaseUrl())}`
    if (!broker && !redirectUrl) {
      window.location.href = `${LOGIN_PATH}?${codeCallbackUrl}${defaultRedirectUrl}`
      return
    }

    const encodedRedirectUrl = redirectUrl ? encodeURIComponent(redirectUrl) : undefined
    const encodedBroker = broker ? encodeURIComponent(broker) : undefined

    let nextUrl = `${LOGIN_PATH}?${codeCallbackUrl}`
    if (encodedRedirectUrl !== undefined) {
      nextUrl += `&redirectUrl=${encodedRedirectUrl}`
    } else {
      nextUrl += defaultRedirectUrl
    }
    if (encodedBroker !== undefined) {
      nextUrl += `&broker=${encodedBroker}`
    }
    window.location.href = nextUrl
  }

  logout(redirectUrl?: string | null): void {
    this.user = null
    this.lastSessionCheck = null

    if (!redirectUrl) {
      window.location.href = `${LOGOUT_PATH}?redirectUrl=${encodeURIComponent(getBrowserBaseUrl())}`
      return
    }

    const encodedRedirectUrl = redirectUrl ? encodeURIComponent(redirectUrl) : undefined

    let nextUrl = `${LOGOUT_PATH}?`
    if (!encodedRedirectUrl) {
      nextUrl += `redirectUrl=${encodedRedirectUrl}`
    }
    window.location.href = nextUrl
  }

  currentUser(): AuthenticatedUser {
    return {
      name: this.user?.username || 'unknown',
      email: this.user?.email || 'unknown',
      id: '',
    }
  }
}

export const authenticator = new SessionPlusAuthenticator()
